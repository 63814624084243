import
{
	Box,
	List,
	ListItem,
	ListItemText,
	Menu,
	MenuItem,
	Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

const ListWrapper = styled(Box)(
	({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu()
{
	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);

	const handleOpen = (): void =>
	{
		setOpen(true);
	};

	const handleClose = (): void =>
	{
		setOpen(false);
	};

	return (
		<>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="flex-end">
				<div>
					Market Cap: 1.25 T USD
				</div>
				<div>
					24h Volume: 81.78 B USD
				</div>
				<div>
					Fear and Greed Index: 61 %
				</div>
				<div>
					BTC Dominance: 56%
				</div>
			</Box>
		</>
	);
}

export default HeaderMenu;

import { FC, useState, createContext, useEffect } from 'react';
import { User } from '../models/user';
import { setToken } from '../axios-config';

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  user?: User;
  setAuthorizedUser: (user?: User) => void;
  logoutUser: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

const getUserFromStorage = (): User | undefined => {
  const user = localStorage['user'];
  if (user) return JSON.parse(user);

  return undefined;
};
const saveUserToStorage = (user: User) => {
  localStorage['user'] = JSON.stringify(user);
};
const clearUserFromStorage = () => {
  localStorage.removeItem('user');
};

const userFromStorage = getUserFromStorage();

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(userFromStorage);

  useEffect(() => {
    if (user) setToken(user.accessToken);
  }, []);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };
  const setAuthorizedUser = (user?: User) => {
    setUser(user);
    if (user) {
      setToken(user.accessToken);
      saveUserToStorage(user);
    }
  };
  const logoutUser = () => {
    if (user) {
      setUser(undefined);
      setToken(undefined);
      clearUserFromStorage();
    }
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        user,
        setAuthorizedUser,
        logoutUser
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

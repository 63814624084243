import axios from "axios";

let authToken;
export function setToken(token?: string) {
	authToken = token;
}

export function addInterceptors() {
	axios.interceptors.request.use((config: any) => {
		if (authToken)
			config.headers['Authorization'] = `Bearer ${authToken}`;

		config.headers['Content-Type'] = 'application/json';
		config.headers['Access-Control-Allow-Origin'] = '*';

		return config;
	},
		error => {
			return Promise.reject(error);
		});

	axios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
            // TODO
			/*if (error.response.status === 401) {
				this.logout();
			}*/

			return Promise.reject(error);
		}
	);
}

import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
	const content = useRoutes(router);

	return (
		<ThemeProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<GoogleOAuthProvider clientId="438652466269-l15pr1087drpc2pnvjo2v8cjsimk5umv.apps.googleusercontent.com">
					<CssBaseline />
					{content}
				</GoogleOAuthProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
export default App;

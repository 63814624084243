import { useContext } from 'react';

import {
Box,
alpha,
Stack,
lighten,
Divider,
IconButton,
Container,
Tooltip,
styled,
useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';

const HeaderWrapper = styled(Box)(
	({ theme }) => `
        height: 60px;
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        justify-content: space-between;
        width: 100%;
`
);

function TopNavbar() {
	const { sidebarToggle, toggleSidebar, user } = useContext(SidebarContext);
	const theme = useTheme();

	return (
		<HeaderWrapper
			display="flex"
			alignItems="center"
			sx={{
				marginBottom: "20px",
				boxShadow:
					theme.palette.mode === 'dark'
						? `0 1px 0 ${alpha(
							lighten(theme.colors.primary.main, 0.7),
							0.15
						)}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
						: `0px 2px 8px -3px ${alpha(
							theme.colors.alpha.black[100],
							0.2
						)}, 0px 5px 22px -4px ${alpha(
							theme.colors.alpha.black[100],
							0.1
						)}`
			}}
		>
			<Container maxWidth="lg" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
				<Stack
					direction="row"
					divider={<Divider orientation="vertical" flexItem />}
					alignItems="center"
					spacing={2}
				>
					<HeaderMenu />
				</Stack>
				<Box display="flex" alignItems="center">
					<HeaderButtons />
					{user && <HeaderUserbox />} 
					<Box
						component="span"
						sx={{
							ml: 2,
							display: { lg: 'none', xs: 'inline-block' }
						}}
					>
						<Tooltip arrow title="Toggle Menu">
							<IconButton color="primary" onClick={toggleSidebar}>
								{!sidebarToggle ? (
									<MenuTwoToneIcon fontSize="small" />
								) : (
									<CloseTwoToneIcon fontSize="small" />
								)}
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</Container>
		</HeaderWrapper>
	);
}

export default TopNavbar;

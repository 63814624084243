import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SidebarContext } from '../contexts/SidebarContext';

const ProtectedRoute = ({ children }) => {

	const { user } = useContext(SidebarContext);
	if (!user) {
		return <Navigate to="/login" replace />;
	}
	return children;
};

export default ProtectedRoute;
